import React from "react"
import { graphql, Link } from "gatsby"

export default function ProjectThumbnail(props) {
  return (
    <div className="project-thumbnail">
      <Link to={props.linkUrl}>
        <div style={{position:"relative"}}>
          <div className="overlay transition">
            <p className="is-size-3">view project</p>
          </div>
          <img src={props.thumbnailImage} />
        </div>
        <p>{props.projectTitle}</p>
      </Link>    
    </div>
  )
}