import React from "react"
import ProjectThumbnail from "../components/project-thumbnail"

export default function ProjectList(props) {

  var columnClass = "is-half";
  var columnContainerClass = "is-8";
  if (props.layout == "half") {
    columnClass = "is-half";
    columnContainerClass = "is-8";
  }
  else if (props.layout == "third") {
    columnClass = "is-one-third";
    columnContainerClass = "is-4";
  }

  let sortedList = props.projects.sort((a, b) => 
    {
      return (parseInt(a.node.frontmatter.sort) - parseInt(b.node.frontmatter.sort));
    });

  return (
    <div>
      <h2 className="title is-2">
        {props.preHeading &&
          <>
            <span class="is-size-4">{props.preHeading}</span><br/>
          </>
        }
        {props.title}
      </h2>

      <div className={"columns is-multiline is-variable " + columnContainerClass}>

        {sortedList.map(({ node }, index) => (
          <div key={index} className={"column " + columnClass}>
            <ProjectThumbnail linkUrl={node.frontmatter.slug} thumbnailImage={node.frontmatter.mediaRoot + node.frontmatter.thumbnail} projectTitle={node.frontmatter.title} />
          </div>
        ))}
      </div>
    </div>
  )
}