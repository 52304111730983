import React from "react"
import Layout from "../components/layout"
import Banner from "../components/banner"
import ProjectList from "../components/project-list"
import { graphql } from "gatsby"

export default function Work({ data }) {

  let clientProjects = data.allMarkdownRemark.edges.filter(({ node }) => (node.frontmatter.category == "client"));
  let ootsProjects = data.allMarkdownRemark.edges.filter(({ node }) => (node.frontmatter.category == "oots"));
  let personalProjects = data.allMarkdownRemark.edges.filter(({ node }) => (node.frontmatter.category == "personal"));

  const bannerUrl = "/laptop-on-table-banner.jpg";

  return (
    <Layout>
      <Banner title="&#8220;Pay attention to what you're passionate about when no one is paying you.&#8221; – Jessica Walsh" imageUrl={bannerUrl}>
      </Banner>

      <section className="section">
        <div className="container">
          <ProjectList projects={clientProjects} layout="third" title="Clients" preHeading="Work for" />
        </div>
      </section>

      <section className="section">
        <div className="container">
          <ProjectList projects={ootsProjects} layout="third" title="Out of the Sandbox" preHeading="Work for" />
        </div>
      </section>

      <section className="section">
        <div className="container">
          <ProjectList projects={personalProjects} layout="third"title="Personal Projects" preHeading="Coming soon &#x1F680;"/>
        </div>
      </section>  
    </Layout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            title
            category
            recent
            thumbnail
            mediaRoot
            sort
          }
          excerpt
        }
      }
    }
  }
`